import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Autocomplete, Box, Button, Collapse, List, ListItemButton, ListItemText, TextField } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useMailProvider } from '../../../../context/MailContext'
import { getTranslatation } from '../../../../utils/i18n/getTranslatation'
import { API_URL, requester } from '../../../../utils/requester/requester'
import { ObsCategory, Observation, ObservationCategory, ObservationCategoryValues } from '../../../../utils/types'
import { NatureContentEditorStyle, NatureContentItemStyle, NatureContentObservation, NatureContentStyle } from './../nature/_styles'

export const Nature = () => {
    const { t } = getTranslatation()
    const [url, setUrl] = useState<string>('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [categoryOpen, setCategoryOpen] = useState<Map<string, boolean>>(new Map())
    const [obs, setObs] = useState<Array<ObsCategory>>([])
    const [animalList, setAnimalList] = useState<Array<ObsCategory>>([])
    const [animalCategory, setAnimalCategory] = useState<ObservationCategory | null>(null)
    const [animalHref, setAnimalHref] = useState<string | null>(null)
    const [animalName, setAnimalName] = useState<string | null>(null)
    const { setContent } = useMailProvider()

    // Use Effects
    useEffect(() => {
        setUrl(encodeURI(`${API_URL}/mail/template?type=nature&title=${title}&description=${description}&obs=${JSON.stringify(obs)}`))
        setContent(`type=nature&title=${title}&description=${description}&obs=${JSON.stringify(obs)}`)
    }, [title, description, obs])

    useEffect(() => {
        // Init collapse map
        const map = new Map<string, boolean>()
        animalList.forEach(item => {
            map.set(item.name, false)
        })
        setCategoryOpen(map)

        // Get mail observations
        getAnimalList()
    }, [])

    // Handlers and events
    const onTitleChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(evt.target.value)
    }

    const onDescChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(evt.target.value)
    }

    const categoryCollapseHandle = (category: string, open: boolean) => {
        const map = new Map(categoryOpen)
        map.set(category, open)
        setCategoryOpen(map)
    }

    const onAnimalTypeChange = (value: ObservationCategory | null) => {
        setAnimalCategory(value)
    }

    const onAnimalNameChange = (value: string | null) => {
        setAnimalName(value)
    }

    const onAnimalHrefChange = (value: string | null) => {
        setAnimalHref(value)
    }

    const getAnimalList = () => {
        requester<Array<ObsCategory>>('/mail/observations').then(response => {
            setAnimalList(response.data ?? [])
        })
    }

    const addAnimalHandler = () => {
        requester(
            '/mail/observations',
            JSON.stringify({
                name: animalName,
                category: animalCategory,
                href: animalHref,
            }),
            null,
            'POST'
        ).then(() => {
            getAnimalList()
            setAnimalHref('')
            setAnimalCategory(null)
            setAnimalName('')
        })
    }

    const onAnimalSelectHandler = (category: string, animal: Observation) => {
        const newObs = [...obs]
        const cat = newObs.filter(e => e.name === category)
        if (cat && cat[0]) {
            const animalIndex = cat[0].items.findIndex(e => e.name === animal.name)
            if (animalIndex > -1) {
                cat[0].items.splice(animalIndex, 1)
                if (cat[0].items.length == 0) {
                    const catIndex = newObs.findIndex(e => e.name === category)
                    newObs.splice(catIndex, 1)
                }
            } else cat[0].items.push(animal)
        } else {
            newObs.push({
                name: category,
                items: [animal],
            })
        }
        setObs(newObs)
    }

    return (
        <Box sx={NatureContentStyle}>
            <Box sx={NatureContentItemStyle}>
                <iframe height="100%" width="100%" src={url}></iframe>
            </Box>
            <Box sx={{ ...NatureContentItemStyle, ...NatureContentEditorStyle }}>
                <TextField label={t('title', true)} onChange={evt => onTitleChange(evt)} />
                <TextField label={t('description', true)} onChange={evt => onDescChange(evt)} />
                <Box sx={{ ...NatureContentObservation, ...NatureContentEditorStyle }}>
                    <List>
                        {animalList.map((value, index) => {
                            return (
                                <div key={value.name + index}>
                                    <ListItemButton
                                        onClick={() => {
                                            categoryCollapseHandle(value.name, !categoryOpen.get(value.name))
                                        }}
                                        sx={{ backgroundColor: '#CCE2AF', borderRadius: '10px', marginBottom: '0.1rem' }}
                                    >
                                        <ListItemText primary={value.name} />
                                        {categoryOpen.get(value.name) ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    {value.items.map((item, index) => {
                                        return (
                                            <Collapse
                                                in={categoryOpen.get(value.name)}
                                                sx={{
                                                    borderRadius: '10px',
                                                    marginBottom: '0.1rem',
                                                    backgroundColor:
                                                        obs.filter(e => e.name === item.category)[0]?.items.filter(e => e.name === item.name).length >
                                                        0
                                                            ? '#e7f0dd'
                                                            : '#fff',
                                                }}
                                                key={item.name + index}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <List component="div" disablePadding>
                                                    <ListItemButton
                                                        sx={{ pl: 4 }}
                                                        onClick={() => {
                                                            onAnimalSelectHandler(value.name, item)
                                                        }}
                                                    >
                                                        <ListItemText primary={item.name} />
                                                    </ListItemButton>
                                                </List>
                                            </Collapse>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </List>

                    <Autocomplete
                        disablePortal
                        options={ObservationCategoryValues}
                        sx={{ width: '100%', marginTop: '3rem' }}
                        getOptionLabel={option => {
                            return option
                        }}
                        value={animalCategory}
                        onChange={(event, value) => onAnimalTypeChange(value)}
                        renderInput={params => <TextField {...params} label={`${t('type', true)} ${t('of')} ${'animal'}`} />}
                    />
                    <TextField value={animalName} label={t('name', true)} onChange={evt => onAnimalNameChange(evt.target.value)} />
                    <TextField value={animalHref} label={t('link', true)} onChange={evt => onAnimalHrefChange(evt.target.value)} />
                    <Button variant="contained" onClick={addAnimalHandler}>
                        {t('add', true)}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
