import React, { useMemo, useState } from 'react'
import { Autocomplete, Paper, TextField } from '@mui/material'
import { getTranslatation } from '../../utils/i18n/getTranslatation'
import { MailStyles } from './_styles'
import { Nature } from './mailTypes/nature/Nature'
import { Sender } from './sender/Sender'
import MailProvider from '../../context/MailContext'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import { FreeMail } from './mailTypes/free/FreeMail'

export const Mail = () => {
    const { t } = getTranslatation()
    const [type, setType] = useState<string | undefined>()

    const mailTypes = [
        {
            name: 'nature',
            title: `${t('hike', true)} ${t('nature')}`,
        },
        {
            name: 'free',
            title: `${t('free', true)}`,
        },
    ]

    const mailComponent = useMemo(() => {
        switch (type) {
            case 'nature':
                return <Nature />
            default:
            case 'free':
                return <FreeMail />
        }
    }, [type])

    return (
        <MailProvider>
            <MenuHeader />
            <Paper sx={MailStyles}>
                <Autocomplete
                    disablePortal
                    options={mailTypes}
                    sx={{ width: 300 }}
                    getOptionLabel={option => {
                        return option.title
                    }}
                    onChange={(event, value) => setType(value?.name)}
                    renderInput={params => <TextField {...params} label={`${t('type', true)} ${t('of')} ${'mail'}`} />}
                />
                {mailComponent}
                {type && <Sender />}
            </Paper>
        </MailProvider>
    )
}
